import { Frame, motion } from "framer"

export function ChangeTextColorWithHover() {
    return (
        <Frame
            as={motion.div}
            size={300}
            background={null}
            center
            initial={{ color: "black" }}
            whileHover={{ color: "orange" }}
        >
            Prejdite cez mňa!
        </Frame>
    )
}
